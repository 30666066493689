const LazyAxios = require('lazy-axios');

const X_RIA_SOURCE = { 'X-Ria-Source': 'vue' };
const target = TARGET;
const mode = process.env.NODE_ENV;
const isProduction = mode === 'production';

let autoHost;
switch (process.env.NODE_ENV) {
  case 'development':
    autoHost = 'autolocal.ria.com';
    break;
  case 'dev-cluster':
    autoHost = 'dev-auto.ria.com';
    break;
  case 'production':
  default:
    autoHost = 'auto.ria.com';
}

function calculateOrigin(target, mode, path = '') {
  switch (target) {
    case 'node':

      const host = autoHost;
      const protocol = mode === 'development' ? (process.env.RIA_SCHEME || 'https') : 'https';
      if (path === 'ap140') {
        let autoHost140;
        switch (process.env.NODE_ENV) {
          case 'development':
            autoHost140 = 'https://autolocal.ria.com';
            break;
          case 'dev-cluster':
            autoHost140 = 'https://dev-auto.ria.com';
            break;
          case 'production':
          default:
            autoHost140 = 'http://10.1.53.92';
        }
        return autoHost140;
      }
      return `${protocol}://${host}`;

    case 'web':
      return location.origin
        ? location.origin
        : `${location.protocol.slice(0, -1)}://${location.host}`;
    default: {
      throw new Error(`Unknown target: ${target}`);
    }
  }
}

const originAP140 = calculateOrigin(target, mode, 'ap140');
const origin = calculateOrigin(target, mode);
const cfg = {
  target, mode, origin, isProduction,
};

const isServer = (target === 'node');

const graphql = require(`./graphql/${target}`)(cfg);

const paths = {
  userData: {
    query: `${origin}/users/information`,
  },
  _slonik: {
    query: {
      url: `${origin}/slonikAccessStat`,
      method: 'POST',
      timeout: 1000,
    },
  },
  _myMenuAccessStat: {
    query: {
      url: `${origin}/myMenuAccessStat`,
      method: 'GET',
      timeout: 1000,
    },
  },
  _phoneAccessStat: {
    query: {
      url: `${origin}/phoneAccessStat`,
      method: 'GET',
      timeout: 1000,
    },
  },
  _levelsOpenStat: {
    query: {
      url: `${origin}/statistics/levels-open/`,
      method: 'GET',
      timeout: 1000,
    },
  },
  _checkCarStat: {
    query: {
      url: `${origin}/statistics/check-car/`,
      method: 'GET',
      timeout: 1000,
    },
  },
  dispatchCatalog: {
    query: {
      url: `${origin}/dispatch`,
      method: 'POST',
      timeout: 2000,
    },
  },
  dispatchAgroCatalog: {
    query: {
      url: `${origin}/dispatch/agro/`,
      method: 'POST',
      timeout: 2000,
    },
  },
  search: {
    query: {
      // @todo url
      url: `${origin}/demo/bu/searchPage/search/indexes/{indices}`,
      method: 'GET',
      timeout: 5000,
      headers: {
        'x-identifier': 'vue',
        ...X_RIA_SOURCE,
      },
    },
  },
  queryConvertNew: {
    query: {
      url: `${origin}/api/amazing-search/query/new`,
      method: 'GET',
      timeout: 5000,
    },
  },
  queryConvert: {
    query: {
      url: `${origin}/api/amazing-search/query/old`,
      method: 'GET',
      timeout: 5000,
    },
  },
  putRaitingShow: {
    query: {
      url: `${origin}/inspectorRating/api/public/putRaitingShow/`,
      method: 'PUT',
      timeout: 5000,
    },
  },
  categories: {
    query: {
      url: `${origin}/demo/bu/mainPage/categories`,
      timeout: 5000,
    },
  },
  countries: {
    query: {
      url: `${origin}/demo/api/countries`,
      timeout: 5000,
    },
  },
  fuels: {
    query: {
      url: `${origin}/demo/api/fuels`,
      timeout: 5000,
    },
  },
  colors: {
    query: {
      url: `${origin}/demo/api/colors`,
      timeout: 5000,
    },
  },
  categories_newAuto: {
    query: {
      url: `${origin}/newauto/api/categories`,
      timeout: 5000,
    },
  },
  bodies: {
    query: {
      url: `${origin}/api/categories/{category}/bodystyles`,
      timeout: 5000,
    },
  },
  bodyModel: {
    query: {
      // ?modelId=33483
      url: `${origin}/demo/bu/searchPage/v2/modelBody`,
      timeout: 5000,
    },
  },
  fastSearchLinks: {
    query: {
      url: `${origin}/fast-search/api/public/links/random/`,
      timeout: 1000,
    },
  },
  options: {
    query: {
      url: `${origin}/api/categories/{category}/auto_options/_group`,
      timeout: 5000,
    },
  },
  drives: {
    query: {
      url: `${origin}/api/categories/{category}/driverTypes`,
      timeout: 5000,
      headers: { ...X_RIA_SOURCE },
    },
  },
  gearboxes: {
    query: {
      url: `${origin}/api/categories/{category}/gearboxes`,
      timeout: 5000,
    },
  },
  bransForCategory: {
    query: {
      url: `${origin}/demo/api/categories/{category}/brands/_active/_with_count/_with_country`,
      timeout: 5000,
    },
  },
  suggestEntity: {
    query: {
      method: 'GET',
      url: `${origin}/api/suggest/{entity}`,
      timeout: 5000,
    },
  },
  suggestModel: {
    query: {
      url: `${origin}/api/suggest/models`,
      timeout: 5000,
    },
  },
  modelsForBrand: {
    query: {
      url: `${origin}/api/marks/{brand}/models/_group/_with_count`,
      timeout: 5000,
    },
  },
  modelsForBrandWithCategory: {
    query: {
      url: `${origin}/api/categories/{category}/marks/{brand}/models/_active/_with_count`,
      timeout: 5000,
    },
  },
  bransForCategory_newAuto: {
    query: {
      url: `${origin}/newauto_blocks/autosalon/marks`,
      timeout: 5000,
    },
  },
  popularBrands: {
    query: {
      url: `${origin}/demo/bu/mainPage/categories/popularBrands`,
      timeout: 5000,
    },
  },
  popularBrandsNew: {
    query: {
      url: `${origin}/demo/bu/mainPage/categories/popularBrandsNew`,
      timeout: 5000,
    },
  },
  modelsForBrand_newAuto: {
    query: {
      url: `${origin}/newauto_blocks/marka/models`,
      timeout: 5000,
    },
  },
  regionCenters: {
    query: {
      url: `${origin}/demo/api/states`,
      timeout: 5000,
    },
  },
  zones: {
    query: {
      url: `${origin}/api/regions`,
      timeout: 5000,
    },
  },
  cities: {
    query: {
      url: `${origin}/api/cities`,
      timeout: 5000,
    },
  },
  citiesInState: {
    query: {
      url: `${origin}/api/states/{id}/cities`,
      timeout: 5000,
    },
  },
  citySuggest: {
    query: {
      url: `${origin}/api/suggest/cities`,
      timeout: 5000,
    },
  },
  transportCategories: {
    query: {
      url: `${origin}/demo/bu/mainPage/subCategories`,
      timeout: 5000,
    },
  },
  rotatorMain: {
    query: {
      url: `${origin}/demo/bu/mainPage/rotator/main`,
      timeout: 5000,
    },
  },
  rotatorMainItem: {
    query: {
      url: `${origin}/demo/bu/mainPage/rotator/item/{id}`,
      timeout: 5000,
    },
  },
  countersPerDayHour: {
    query: {
      url: `${origin}/demo/bu/mainPage/counters/day/hour/`,
      timeout: 5000,
    },
  },
  countersTotal: {
    query: {
      url: `${origin}/demo/bu/mainPage/counters/auto`,
      timeout: 5000,
    },
  },
  market_getProductCounter: {
    query: {
      url: `${origin}/bu_blocks/market/getProductCounter`,
      timeout: 5000,
    },
  },
  news_slonikInfo: {
    query: {
      url: `${origin}/content/news/templetify/slonikInfo/`,
      timeout: 1000,
    },
  },
  newsSearchItem: {
    query: {
      url: `${origin}/content/news/templetify/search/`,
      timeout: 1000,
    },
  },
  news_search: {
    query: {
      url: `${origin}/content/news/templetify/search/`,
      timeout: 1000,
    },
  },
  news_reviews: {
    query: {
      url: `${origin}/content/news/templetify/reviews/`,
      timeout: 5000,
    },
  },
  newsAutoleader: {
    query: {
      url: `${origin}/content/news/templetify/autoleader/nominationInformer/`,
      timeout: 1000,
    },
  },
  news_getLandingMainLink: {
    query: {
      url: `${origin}/content/news/templetify/getLandingMainLink/`,
      timeout: 1000,
    },
  },
  mainPageCatalogs: {
    query: {
      url: `${origin}/demo/bu/mainPage/catalogs`,
      timeout: 5000,
    },
  },
  seoCommon: {
    query: {
      url: `${origin}/seo/common/{target}`,
      timeout: 5000,
    },
  },
  seoCompany: {
    query: {
      url: `${origin}/seo/company`,
      timeout: 5000,
    },
  },
  searchHistoryLast: {
    query: {
      method: 'POST',
      url: `${origin}/search-history/last/`,
      timeout: 5000,
    },
  },
  subscribe: {
    query: {
      method: 'POST',
      url: `${origin}/users/subscribe/`,
    },
  },
  auto3: {
    query: {
      method: 'POST',
      url: `${origin}/ajax.php`,
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
    },
  },
  company: {
    query: {
      url: `${origin}/company_api/api/company/{id}`,
      timeout: 5000,
    },
  },
  sendToKayako: {
    query: {
      method: 'POST',
      url: `${origin}/users/sendKayako/`,
    },
  },
  searchV2ViewAuto: {
    query: {
      url: `${origin}/demo/bu/searchPage/v2/view/auto/{id}`,
    },
  },
  NaViewAuto: {
    query: {
      method: 'POST',
      url: `${origin}/newauto/api/auto/`,
      timeout: 7500,
    },
  },
  graphql,
  autotestData: {
    query: {
      method: 'GET',
      url: `${origin}/autotest/sto/is_set_by_auto_id`,
      responseType: 'json',
      timeout: isServer ? 1000 : 5000,
    },
  },
  autotestOrders: {
    query: {
      method: 'GET',
      url: `${origin}/autotest/orders`,
      responseType: 'json',
      timeout: isServer ? 1000 : 5000,
    },
  },
  naisReport: {
    query: {
      method: 'GET',
      url: `${origin}/autotest/nais/{plateNumber}`,
      responseType: 'json',
      timeout: isServer ? 1000 : 5000,
    },
  },
  autotestCabinetAbout: {
    query: {
      method: 'GET',
      url: `${origin}/cabinet/api/autocheck/orders/about`,
      responseType: 'json',
      timeout: isServer ? 1000 : 5000,
    },
  },
  autotestCabinetOrders: {
    query: {
      method: 'GET',
      url: `${origin}/cabinet/api/autocheck/orders`,
      responseType: 'json',
      timeout: isServer ? 1000 : 5000,
    },
  },
  // можно отказаться от этого, когда в graph_ql добавяться данные о овнере(компании) и парам abroad
  searchBlockData: {
    query: {
      method: 'GET',
      url: `${origin}/demo/bu/searchPage/v3/view/auto/{autoId}`,
      responseType: 'json',
      timeout: isServer ? 1000 : 5000,
    },
  },
  position: {
    query: {
      method: 'GET',
      url: `${origin}/levels/position/{autoId}`,
      responseType: 'json',
      timeout: isServer ? 1000 : 5000,
    },
  },
  forecastPosition: {
    query: {
      method: 'GET',
      url: `${origin}/levels/forecastPosition/{top}/{autoId}`,
      responseType: 'json',
      timeout: 5000,
    },
  },
  forecastPositionVIN: {
    query: {
      method: 'GET',
      url: `${origin}/levels/forecastPosition/{top}/{autoId}?promiseVIN=1`,
      responseType: 'json',
      timeout: 5000,
    },
  },
  currentPosition: {
    query: {
      method: 'GET',
      url: `${origin}/levels/position/{autoId}`,
      responseType: 'json',
      timeout: 5000,
    },
  },
  chatRia: {
    query: {
      method: 'GET',
      url: `${origin}/api/v1/has-chat?user_id={userId}&project_id=3`,
      responseType: 'json',
      headers: {
        'Cache-Control': 'no-cache',
      },
      timeout: 600,
    },
  },
  sendContactMessage: {
    query: {
      method: 'POST',
      url: `${origin}/ph/ajax/sendContactMessage/`,
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
      timeout: 3000,
    },
  },
  balance: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/v2/main_page/balance`,
      timeout: isServer ? 1000 : 5000,
    },
  },
  packages: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/constants/packages/price`,
      timeout: isServer ? 500 : 3000,
    },
  },
  constantsPrice: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/constants/price`,
      timeout: isServer ? 500 : 3000,
    },
  },
  notifications: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/notifications/preview?noCache=true`,
      timeout: isServer ? 500 : 3000,
      headers: { ...X_RIA_SOURCE },
    },
  },
  notifications_PATCH: {
    query: {
      method: 'PATCH',
      responseType: 'json',
      url: `${origin}/notifications/api/public/`,
      timeout: 2000,
      headers: {
        'Content-type': 'application/json',
        ...X_RIA_SOURCE,
      },
    },
  },
  notificationsCount: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/notifications/api/public/count`,
      timeout: isServer ? 1500 : 3000,
      headers: { ...X_RIA_SOURCE },
    },
  },
  notificationsRingbell: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/notifications/api/public/ringbell`,
      timeout: isServer ? 1500 : 3000,
      headers: { ...X_RIA_SOURCE },
    },
  },
  notificationsSearch: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/notifications/api/public/`,
      timeout: isServer ? 1000 : 3000,
      headers: {
        'Content-type': 'application/json',
        ...X_RIA_SOURCE,
      },
    },
  },
  sendCallBackRequest: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/api/notification-channels-proxy/public/sendout/{triggerId}/`,
      timeout: isServer ? 1000 : 3000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  subscriptions: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/subscribe/preview/`,
      timeout: isServer ? 500 : 3000,
    },
  },
  leftBar: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/left_bar?content=blocks`,
      timeout: isServer ? 1 : 3000,
    },
  },
  leftBarCounters: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/left_bar?content=counters`,
      timeout: isServer ? 1 : 5000,
    },
  },
  unpaidOrders: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/billing/unpaidOrders`,
      timeout: isServer ? 500 : 3000,
    },
  },
  content: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/main_page/content`,
      timeout: isServer ? 1000 : 3000,
    },
  },
  alerts: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/reminders`,
      timeout: isServer ? 1000 : 3000,
      headers: { ...X_RIA_SOURCE },
    },
  },
  statisticCounters: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/main_page/statistic`,
      timeout: isServer ? 1 : 5000,
    },
  },
  forBusiness: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/b2bCooperationList/`,
      timeout: isServer ? 500 : 3000,
    },
  },
  notepad: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/notepad/preview/`,
      timeout: isServer ? 500 : 3000,
    },
  },
  rating: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/users/karma`,
      timeout: isServer ? 1500 : 3000,
    },
  },
  tags: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/users/tags`,
      timeout: isServer ? 1000 : 3000,
    },
  },
  segment: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/api/segment/public/`,
      timeout: isServer ? 1500 : 3000,
      headers: {
        'X-Source': 'vue',
      },
    },
  },
  ratingState: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/users/karma/state`,
      timeout: isServer ? 1500 : 3000,
    },
  },
  userInfoCabinet: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/user/information`,
      timeout: isServer ? 1500 : 5000,
    },
  },
  _authCabinet: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/user`,
      timeout: isServer ? 1500 : 3000,
    },
  },
  blocks: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/main_page/blocks`,
      timeout: isServer ? 1000 : 3000,
    },
  },
  publication_statistic: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${originAP140}/cabinet/api/publications`,
      timeout: isServer ? 1000 : 5000,
    },
  },
  states: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/api/states`,
      timeout: isServer ? 1000 : 3000,
    },
  },
  userLevels: {
    query: {
      method: 'POST',
      url: `${origin}/user/levels/`,
      timeout: 60000,
    },
  },
  publicationPayment: {
    query: {
      method: 'POST',
      url: `${origin}/user/levels/{advertisementId}{query}`,
    },
  },
  publicationPaymentPrivat24: {
    query: {
      method: 'POST',
      url: `${origin}/users/createPrivat24Payment/`,
    },
  },
  userRestore: {
    query: {
      method: 'POST',
      url: `${origin}/api/publications/public/restore`,
    },
  },
  estimatorRequestsWithPage: {
    query: {
      method: 'GET',
      url: `${origin}/estimator/api/public/autos-with-page`,
      timeout: 30000,
    },
  },
  estimatorItemCount: {
    query: {
      method: 'GET',
      url: `${origin}/estimator/api/public/count`,
      timeout: 1000,
    },
  },
  estimatorItemsByPage: {
    query: {
      method: 'GET',
      url: `${origin}/estimator/api/public/count`,
      timeout: 15000,
    },
  },
  saveEstimatorField: {
    query: {
      method: 'POST',
      url: `${origin}/estimator/api/public/saveField`,
      timeout: 1000,
    },
  },
  estimatorReports: {
    query: {
      method: 'POST',
      url: `${origin}/estimator/api/public/reports`,
      timeout: 5000,
    },
  },
  averagePrice: {
    query: {
      method: 'GET',
      url: `${origin}/api/averagePrice`,
      timeout: 5000,
    },
  },
  stepPublication: {
    query: {
      method: 'GET',
      url: `${origin}/api/publication-page/auto/{advertisementId}/{queryType}`,
      timeout: 8000,
    },
  },
  updateStepPublication: {
    query: {
      method: 'POST',
      url: `${origin}/api/publication-page/auto/{advertisementId}/{queryType}`,
      timeout: 8000,
    },
  },
  sendReviewTechCheck: {
    query: {
      method: 'POST',
      url: `${origin}/autotest/api/public/reviews/`,
      timeout: 5000,
    },
  },
  reviewsTechCheckExists: {
    query: {
      method: 'GET',
      url: `${origin}/autotest/api/public/review/{id}/exist`,
      timeout: 1000,
    },
  },
  publicationStepLogger: {
    query: {
      method: 'GET',
      url: `${origin}/statistics/publicationStep`,
      timeout: 2000,
    },
  },
  autoById: {
    query: {
      method: 'GET',
      url: `${origin}/auto/api/public/view/{id}`,
      timeout: 8000,
    },
  },
  getStep3Info: {
    query: {
      method: 'GET',
      url: `${originAP140}/users/step3_v2`,
      timeout: 8000,
    },
  },
  getAutoBuyInfo: {
    query: {
      method: 'GET',
      url: `${originAP140}/auto/api/public/autobuy/{id}`,
      timeout: 8000,
    },
  },
  getInspectorData: {
    query: {
      method: 'GET',
      url: `${originAP140}/inspectorRating/api/public/getInspectorData/`,
      timeout: 8000,
    },
  },
  getInspectorsRating: {
    query: {
      method: 'GET',
      url: `${origin}/inspectorRating/api/public/ratings/`,
      timeout: 8000,
    },
  },
  getMoreRatings: {
    query: {
      method: 'GET',
      url: `${origin}/inspectorRating/api/public/ratingsMore/`,
      timeout: 8000,
    },
  },
  getInspectorCount: {
    query: {
      method: 'GET',
      url: `${origin}/inspectorRating/api/public/count/`,
      timeout: 8000,
    },
  },
  getInspectorProjectRating: {
    query: {
      method: 'GET',
      url: `${originAP140}/inspectorRating/api/public/project/rating/`,
      timeout: 8000,
    },
  },
  setInspectorProjectRating: {
    query: {
      method: 'PUT',
      url: `${originAP140}/inspectorRating/api/public/project/rating/`,
      timeout: 8000,
    },
  },
  getInspectorEstimateList: {
    query: {
      method: 'GET',
      url: `${origin}/inspectorRating/api/public/estimate/list`,
      timeout: 5000,
    },
  },
  getInspectorReasons: {
    query: {
      method: 'GET',
      url: `${origin}/inspectorRating/api/public/reasons/{type}`,
      timeout: 2000,
    },
  },
  changeInspectorEstimateState: {
    query: {
      method: 'PUT',
      url: `${origin}/inspectorRating/api/public/estimate/update/{id}`,
      timeout: 3000,
    },
  },
  saveInspectorEstimateState: {
    query: {
      method: 'POST',
      url: `${origin}/inspectorRating/api/public/estimate/save/{id}`,
      timeout: 8000,
    },
  },
  saveUserRating: {
    query: {
      method: 'POST',
      url: `${origin}/api/ratings/public/owners/{id}/ratings`,
      timeout: 8000,
    },
  },
  getUserRating: {
    query: {
      method: 'GET',
      url: `${origin}/api/ratings/public/marks/{id}`,
      timeout: 8000,
    },
  },
  exchangeRates: {
    query: {
      method: 'GET',
      url: `${originAP140}/api/form/exchangeRates/`,
      timeout: 3000,
    },
  },
  activeMotorcarsWithVIN: {
    query: {
      method: 'GET',
      url: `${originAP140}/demo/bu/searchPage/search/count/activeMotorcarsWithVIN`,
      timeout: 3000,
    },
  },
  callTrackingAccountInfo: {
    query: {
      method: 'GET',
      url: `${originAP140}/api/call-tracking/public/account-info/`,
      timeout: 3000,
      headers: { ...X_RIA_SOURCE },
    },
  },
  putInspectorHistory: {
    query: {
      method: 'PUT',
      url: `${originAP140}/inspectorRating/api/public/putShareBage/`,
      timeout: 8000,
    },
  },
  sendPhoneSms: {
    query: {
      method: 'POST',
      url: `${originAP140}/api/video-calls/public/sendToSms/`,
      timeout: 8000,
    },
  },
  autofill: {
    query: {
      method: 'GET',
      url: `${origin}/24/api/public/autofill/{plateNumber}/`,
      timeout: 10000,
    },
  },
  checkPhone: {
    query: {
      method: 'POST',
      url: `${origin}/login-ajax/check-phone/`,
      timeout: 5000,
    },
  },
  callPassportScenario: {
    query: {
      method: 'POST',
      url: `${origin}/login-ajax/{scenario}/`,
      timeout: 5000,
    },
  },
  changePass: {
    query: {
      method: 'POST',
      url: `${origin}/login-proxy/{scenario}`,
      timeout: 5000,
    },
  },
  setUserName: {
    query: {
      method: 'PUT',
      url: `${origin}/api/form/user/name`,
      timeout: 5000,
    },
  },
  addPhoto: {
    query: {
      method: 'POST',
      url: 'https://{staticId}.riastatic.com/upload/v4/',
      // при поганому інтернеті потрібний більший таймаут
      timeout: 60000,
    },
  },
  deletePhoto: {
    query: {
      method: 'DELETE',
      url: `${origin}/api/autos/0/photo/{photoId}`,
      timeout: 5000,
    },
  },
  getFormTree: {
    query: {
      method: 'GET',
      url: `${origin}/api/form/tree`,
      timeout: 5000,
    },
  },
  formElementChanged: {
    query: {
      method: 'POST',
      url: `${origin}/api/form/element/changed/{element}`,
      timeout: 5000,
    },
  },
  calcAutoBuyPrice: {
    query: {
      method: 'POST',
      url: `${origin}/api/form/autobuy/calculate`,
      timeout: 5000,
    },
  },
  addAuto: {
    query: {
      method: 'POST',
      url: `${origin}/api/form/save`,
      timeout: 5000,
    },
  },
  searchCarCount: {
    query: {
      method: 'GET',
      url: `${originAP140}/demo/bu/searchPage/search/count`,
      timeout: 3000,
    },
  },
  sendPartnersLog: {
    query: {
      method: 'POST',
      url: `${origin}/24/api/public/partnersLog/`,
      timeout: 3000,
    },
  },
  sendPartnerToCM: {
    query: {
      method: 'POST',
      url: `${origin}/24/api/public/sendPartnerToCM/`,
      timeout: 3000,
    },
  },
  fetchFilterParam: {
    query: {
      method: 'GET',
      url: `${origin}/cabinet/api/cars/filter?type={type}&langId={langId}`,
      timeout: 3000,
    },
  },
  getVinCodeDataByUserId: {
    query: {
      method: 'GET',
      url: `${origin}/cabinet/api/user/vin_code_data`,
      timeout: 3000,
    },
  },
  conditions: {
    query: {
      method: 'GET',
      url: `${originAP140}/24/api/public/conditions/`,
      timeout: 1000,
    },
  },
  autoRia24UserAdvertisements: {
    query: {
      method: 'GET',
      url: `${originAP140}/24/api/public/user/autos/`,
      timeout: 5000,
    },
  },
  autoRia24SecondPageStrucuture: {
    query: {
      method: 'GET',
      url: `${originAP140}/24/api/public/view/second-step/{id}`,
      timeout: 5000,
    },
  },
  autoRia24PartnersCount: {
    query: {
      method: 'GET',
      url: `${originAP140}/24/api/public/partners/count/all/`,
      timeout: 5000,
    },
  },
  autoRia24AddExistingAuto: {
    query: {
      method: 'POST',
      url: `${originAP140}/24/api/public/advertisement/{id}/buyout`,
      timeout: 2000,
    },
  },
  autoRia24Reviews: {
    query: {
      method: 'GET',
      url: `${originAP140}/24/api/public/reviews/?shuffle=1`,
      timeout: 1000,
    },
  },
  autoOrderSendToCM: {
    query: {
      method: 'POST',
      url: `${origin}/users/autoOrderSendToCM`,
      timeout: 3000,
    },
  },
  advertisementStatPublications: {
    query: {
      method: 'GET',
      url: `${originAP140}/cabinet/api/car/{advertisementId}/statistic/publications`,
      timeout: 8000,
    },
  },
  advertisementStatCharts: {
    query: {
      method: 'GET',
      url: `${originAP140}/cabinet/api/car/{advertisementId}/statistic/charts`,
      timeout: 8000,
    },
  },
  advertisementStatTop: {
    query: {
      method: 'GET',
      url: `${originAP140}/cabinet/api/car/{advertisementId}/statistic/top`,
      timeout: 8000,
    },
  },
  advertisementStatOpenPhone: {
    query: {
      method: 'GET',
      url: `${originAP140}/cabinet/api/car/{advertisementId}/statistic/openPhone`,
      timeout: 8000,
    },
  },
  advertisementStat: {
    query: {
      method: 'GET',
      url: `${originAP140}/cabinet/api/car/{advertisementId}/statistic`,
      timeout: 8000,
    },
  },
  addBrandModel: {
    query: {
      method: 'POST',
      url: `${origin}/api/add-brand-model`,
      timeout: 5000,
    },
  },
  auctionPerformance: {
    query: {
      method: 'GET',
      url: `${origin}/api/call-tracking/public/auction/performance`,
      timeout: 5000,
    },
  },
  auctionAutoRate: {
    query: {
      method: 'GET',
      url: `${origin}/api/call-tracking/public/auction/auto-rate-info/{advertisementId}/`,
      timeout: 5000,
    },
  },
  companyCalculator: {
    query: {
      method: 'GET',
      url: `${origin}/cabinet/api/company/statistic/calculator`,
      timeout: 5000,
    },
  },
  companyEfficiency: {
    query: {
      method: 'GET',
      url: `${origin}/cabinet/api/company/statistic`,
      timeout: 5000,
    },
  },
  requestForPartnership: {
    query: {
      method: 'POST',
      url: `${origin}/24/api/public/requestForPartnership/`,
      timeout: 3000,
    },
  },
  getPublicationInfo: {
    query: {
      method: 'GET',
      url: `${origin}/api/prices/public/publication/auto/{autoId}`,
      timeout: 3000,
      headers: {
        'X-Source': 'vue',
      },
    },
  },
  leasingBlock: {
    query: {
      method: 'GET',
      url: `${origin}/api/leasing/public/proposal/{advertisementId}`,
      timeout: 5000,
    },
  },
  searchAPI: {
    query: {
      method: 'GET',
      url: `${origin}/api/search/auto`,
      timeout: 5000,
      headers: {
        'x-identifier': 'vue',
      },
    },
  },
  fetchDefaultLevelInfo: {
    query: {
      method: 'GET',
      url: `${origin}/api/prices/public/level/defaults`,
      timeout: 3000,
      headers: {
        'X-Source': 'vue',
      },
    },
  },
  fetchPayDataForDefaultLevel: {
    query: {
      method: 'GET',
      url: `${origin}/api/prices/public/level/advertiseForFixed/{autoId}/`,
      timeout: 3000,
      headers: {
        'X-Source': 'vue',
      },
    },
  },
  fetchCallTrackingInfo: {
    query: {
      method: 'GET',
      url: `${origin}/api/prices/public/service/callTracking`,
      timeout: 3000,
      headers: {
        'X-Source': 'vue',
      },
    },
  },
  getPersonalManager: {
    query: {
      method: 'GET',
      url: `${origin}/cabinet/api/personal_manager/`,
      timeout: 3000,
    },
  },
  sendMessage: {
    query: {
      method: 'POST',
      url: `${origin}/users/personalManagerNotification/`,
      timeout: 3000,
    },
  },
  sendAuctionFeedback: {
    query: {
      method: 'POST',
      url: `${origin}/users/sendAuctionFeedback/`,
      timeout: 3000,
    },
  },
  auctionGetPreData: {
    query: {
      method: 'GET',
      url: `${origin}/api/auction/public/info/{id}/`,
      timeout: 5000,
    },
  },
  auctionReport: {
    query: {
      method: 'GET',
      url: `${origin}/api/auction/public/tech-check/{id}`,
      timeout: 5000,
    },
  },
  auctionGetStoInfo: {
    query: {
      method: 'GET',
      url: `${origin}/api/auction/public/auction/{advId}/schedules`,
      timeout: 5000,
    },
  },
  auctionBidsInfo: {
    query: {
      method: 'GET',
      url: `${origin}/api/auction/public/auction/{advId}/bids`,
      timeout: 5000,
    },
  },
  auctionAddBookmarks: {
    query: {
      method: 'POST',
      url: `${origin}/user/menu/bookmarks/`,
      timeout: 5000,
    },
  },
  auctionGetBookmarks: {
    query: {
      method: 'GET',
      url: `${origin}/notepad/api/bookmark/list/?content_type_ids=1&content_type_ids=3`,
      timeout: 5000,
    },
  },
  auctionDeleteBookmarks: {
    query: {
      method: 'DELETE',
      url: `${origin}/user/menu/bookmarks/?projectId=1&contentTypeId=1&currentDevice=desktop`,
      timeout: 5000,
    },
  },
  auctionUpdateUser: {
    query: {
      method: 'PUT',
      url: `${origin}/api/auction/public/user`,
      timeout: 2000,
    },
  },
  publicationDiscountsList: {
    query: {
      method: 'GET',
      url: `${origin}/api/discounts/public/refill-discount-types/`,
      timeout: 3000,
    },
  },
  createRefillOrder: {
    query: {
      method: 'POST',
      url: `${origin}/api/discounts/public/create-refill-order/`,
      timeout: 3000,
    },
  },
  promotions: {
    query: {
      method: 'GET',
      url: `${origin}/api/discounts/public/promotions/`,
      timeout: 3000,
    },
  },
  checkPromoCode: {
    query: {
      method: 'GET',
      url: `${origin}/api/discounts/public/promo-code/`,
      timeout: 3000,
    },
  },
  getUserPromoCodes: {
    query: {
      method: 'GET',
      url: `${origin}/api/discounts/public/user-promo-codes/?type=1`,
      timeout: 3000,
    },
  },
  callTracking: {
    query: {
      method: 'GET',
      url: `${origin}/api/call-tracking/public/get-dc-number/`,
      timeout: 3000,
    },
  },
  getSubscribePageInfo: {
    query: {
      method: 'GET',
      url: `${origin}/api/subscribes/public/subscriptions/{param}`,
      timeout: 3000,
    },
  },
  deleteSubscribe: {
    query: {
      method: 'DELETE',
      url: `${origin}/api/subscribes/public/subscription/{projectId}/{channelId}/{subsId}`,
      timeout: 3000,
    },
  },
  createSubscribeEmail: {
    query: {
      method: 'POST',
      url: `${origin}/api/subscribes/public/subs/`,
      timeout: 3000,
    },
  },
  createSubscribeHash: {
    query: {
      method: 'POST',
      url: `${origin}/amazing-search/hashCreator/`,
      timeout: 3000,
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
    },
  },
  editSubscribe: {
    query: {
      method: 'PUT',
      url: `${origin}/api/subscribes/public/subscription/edit/`,
      timeout: 3000,
    },
  },
  saveAsShow: {
    query: {
      method: 'POST',
      url: `${origin}/autotest/saveAsShow/`,
      timeout: 3000,
    },
  },
  getAutotestDataVIN: {
    query: {
      method: 'GET',
      url: `${origin}/autotest/vinProtocol/`,
      timeout: 5000,
    },
  },
  STOList: {
    query: {
      method: 'GET',
      url: `${origin}/autotest/sto`,
      timeout: 3000,
    },
  },
  reviewsAutotest: {
    query: {
      method: 'GET',
      url: `${origin}/content/news/getReviewsAutotest`,
      timeout: 3000,
    },
  },
  getRepublicationInfo: {
    query: {
      method: 'GET',
      url: `${origin}/api/publications/public/aar/preference/`,
      timeout: 3000,
    },
  },
  updateRepublicationInfo: {
    query: {
      method: 'POST',
      url: `${origin}/api/publications/public/aar/preference/`,
      timeout: 3000,
    },
  },
  marketplaceGet: {
    query: {
      method: 'GET',
      url: `${origin}/api/marketplace/public/lots/{id}`,
      timeout: 5000,
    },
  },
  marketplacePassport: {
    query: {
      method: 'GET',
      url: `${origin}/api/marketplace/public/lots/{vin}/passport`,
      timeout: 5000,
    },
  },
  marketplaceReport: {
    query: {
      method: 'GET',
      url: `${origin}/api/marketplace/public/verification/{id}/`,
      timeout: 10000,
    },
  },
  marketplaceRequest: {
    query: {
      method: 'POST',
      url: `${origin}/api/marketplace/public/request/{type}/{id}/`,
      timeout: 5000,
    },
  },
  segmentAgro: {
    query: {
      method: 'GET',
      url: `${origin}/cabinet/api/rotator/agro`,
      timeout: 3000,
    },
  },
  getPromoFreePublication: {
    query: {
      method: 'GET',
      url: `${origin}/cabinet/api/rotator/promoJan`,
      timeout: 3000,
    },
  },
  getCards: {
    query: {
      method: 'GET',
      url: `${origin}/cards/api/public/all/`,
      timeout: 3000,
    },
  },
  deleteCard: {
    query: {
      method: 'DELETE',
      url: `${origin}/cards/api/public/{cardId}`,
      timeout: 3000,
    },
  },
  setMainCard: {
    query: {
      method: 'PATCH',
      responseType: 'json',
      url: `${origin}/cards/api/public/main/{cardId}`,
      timeout: 3000,
    },
  },
  autoDealerBilling: {
    query: {
      method: 'PUT',
      url: `${origin}/demo/bu/billingServices/company/order/`,
      timeout: 5000,
    },
  },
  reCallToMe: {
    query: {
      method: 'POST',
      url: `${origin}/autoajax/user/autosellerconsult`,
      timeout: 5000,
      headers: {
        'content-type': 'application/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
  },
  dealersLogo: {
    query: {
      method: 'GET',
      url: `${origin}/company_api/api/landingRotator`,
      timeout: 3000,
    },
  },
  subscribeBotCheck: {
    query: {
      method: 'GET',
      url: `${origin}/api/subscribes/public/subscriptions/bot`,
      timeout: 3000,
    },
  },
  compareCars: {
    query: {
      method: 'GET',
      url: `${origin}/compare/api/{link}.html`,
      timeout: 5000,
    },
  },
  commonReviewsInfo: {
    query: {
      method: 'GET',
      url: `${origin}/reviews/api/estimate`,
      timeout: 5000,
    },
  },
  getCompareCouples: {
    query: {
      method: 'GET',
      url: `${origin}/content/koa2/templetify/compare/search`,
      timeout: 5000,
    },
  },
  newAutoInformer: {
    query: {
      method: 'GET',
      url: `${origin}/newauto/api/informer/{sort}/`,
      timeout: 5000,
    },
  },
  similarAds: {
    query: {
      method: 'GET',
      url: `${origin}/demo/bu/finalPage/similarAds/{advertisementId}`,
      timeout: 5000,
    },
  },
  carPrices: {
    query: {
      method: 'GET',
      url: `${origin}/api/average`,
      timeout: 5000,
    },
  },
  updateCompanyInfo: {
    query: {
      method: 'POST',
      url: `${origin}/company_api/api/companyUpdate/{id}`,
      timeout: 5000,
    },
  },
  uploadImage: {
    query: {
      method: 'POST',
      url: `${origin}/plupload`,
      timeout: 5000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  },
  setCompanyLogo: {
    query: {
      method: 'POST',
      url: `${origin}/company_api/setLogo`,
      timeout: 5000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  },
  getAllUsers: {
    query: {
      method: 'GET',
      url: `${origin}/users/getMultiAccounts`,
      timeout: 3000,
    },
  },
  deleteUserFromGroup: {
    query: {
      method: 'DELETE',
      url: `${origin}/users/deleteUserFromGroup/{userId}`,
      timeout: 3000,
    },
  },
  checkPspId: {
    query: {
      method: 'GET',
      url: `${origin}/users/checkUserAuth/{userId}`,
      timeout: 3000,
    },
  },
  inspectorSuccessLanding: {
    query: {
      method: 'GET',
      url: `${origin}/inspectorRating/api/public/lending`,
      timeout: 3000,
    },
  },
  sellerRating: {
    query: {
      method: 'GET',
      url: `${origin}/api/ratings/public/owners/{ownerId}/ratings`,
      timeout: 3000,
    },
  },
  downloadInspectorPics: {
    query: {
      method: 'POST',
      url: `${origin}/inspectorRating/api/public/docs`,
      timeout: 5000,
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  },
  companyLogVisit: {
    query: {
      method: 'GET',
      url: `${origin}/company_api/api/company/log_visit/{id}`,
      timeout: 3000,
    },
  },
  autoInterCars: {
    query: {
      method: 'GET',
      url: `${origin}/content/news/autointer/`,
      timeout: 3000,
    },
  },
  countryPartners: {
    query: {
      method: 'GET',
      url: `${origin}/content/news/templetify/autointer/`,
      timeout: 3000,
    },
  },
  autoInterBrands: {
    query: {
      method: 'GET',
      url: `${origin}/content/news/templetify/autointer/`,
      timeout: 3000,
    },
  },
  autoInterNews: {
    query: {
      method: 'GET',
      url: `${origin}/content/news/templetify/search/`,
      timeout: 3000,
    },
  },
  getFavoritesNewsIds: {
    query: {
      method: 'GET',
      url: `${origin}/content/news/psp/favoriteNews/`,
      timeout: 3000,
    },
  },
  addToFavoriteNews: {
    query: {
      method: 'POST',
      url: `${origin}/content/news/psp/favoriteNews/`,
      timeout: 3000,
    },
  },
  autoInterSearch: {
    query: {
      method: 'GET',
      url: `${origin}/content/news/templetify/autointer/`,
      timeout: 3000,
    },
  },
  deleteFromFavoritesNews: {
    query: {
      method: 'POST',
      url: `${origin}/content/news/psp/favoriteNews/`,
      timeout: 3000,
    },
  },
  googleOneTapLogin: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/login-one-tap/`,
      timeout: 5000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getAutoDealerProlongation: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/api/billing-services/public/prolongation/company`,
      timeout: 3000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  postAutoDealerProlongation: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/api/billing-services/public/prolongation/company`,
      timeout: 3000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getProlongation: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/api/billing-services/public/prolongation/refillAccount`,
      timeout: 3000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  postProlongation: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/api/billing-services/public/prolongation/refillAccount`,
      timeout: 3000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getAutoMarketData: {
    query: {
      method: 'GET',
      url: `${origin}/api/market-auto/public/partnerAds`,
      timeout: 3000,
    },
  },
  getGenerationByModelId: {
    query: {
      method: 'GET',
      url: `${origin}/api/models/{id}/generations`,
      timeout: 3000,
    },
  },
  getModificationByGenerationId: {
    query: {
      method: 'GET',
      url: `${origin}/api/generation/{id}/modifications`,
      timeout: 3000,
    },
  },
  getAllModificationsByModel: {
    query: {
      method: 'GET',
      url: `${origin}/api/models/{modelId}/modifications`,
      timeout: 3000,
    },
  },
  getProAccountLeftBar: {
    query: {
      method: 'GET',
      url: `${origin}/cabinet/api/pro-account/left-bar`,
      timeout: 3000,
    },
  },
  getProAccountStatistic: {
    query: {
      method: 'GET',
      url: `${origin}/cabinet/api/pro-account/statistic`,
      timeout: 3000,
    },
  },
  getProServiceData: {
    query: {
      method: 'GET',
      url: `${origin}/api/business-features/public/service/v2/{name}`,
      timeout: 3000,
    },
  },
  getBusinessFeaturesData: {
    query: {
      method: 'GET',
      url: `${origin}/api/business-features/public/{name}`,
      timeout: 3000,
    },
  },
  getBusinessFeaturesDataOnServer: {
    query: {
      method: 'GET',
      url: _TARGET_ === 'server' && 'http://business-features.prod.decluster.ria.com/{name}/{userId}',
      timeout: 3000,
    },
  },
  uploadAdvertisementDocs: {
    query: {
      method: 'PUT',
      url: `${origin}/cabinet/api/directories/declarations/files`,
      timeout: 10000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  },
  uploadPostModerationDocs: {
    query: {
      method: 'PUT',
      url: `${origin}/cabinet/api/directories/documents/files`,
      timeout: 10000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  },
  getAdvertisementDocs: {
    query: {
      method: 'GET',
      url: `${origin}/cabinet/api/declarations`,
      timeout: 3000,
    },
  },
  getCallTrackingByAuto: {
    query: {
      method: 'GET',
      url: `${origin}/api/business-features/public/callTracking/advertisement/{autoId}`,
      timeout: 3000,
    },
  },
  getCallTrackingByUser: {
    query: {
      method: 'GET',
      url: `${origin}/api/business-features/public/service/callTracking`,
      timeout: 3000,
    },
  },
  switchCallTracking: {
    query: {
      method: 'POST',
      url: `${origin}/api/business-features/public/callTracking/advertisement/{autoId}`,
      timeout: 3000,
    },
  },
  createServiceOrder: {
    query: {
      method: 'GET',
      url: `${origin}/api/billing-services/public/makeOrder/{target}`,
      timeout: 2000,
    },
  },
  makeOrder: {
    query: {
      method: 'POST',
      url: `${origin}/api/billing-services/public/makeOrder`,
      timeout: 10000,
    },
  },
  getAverageFormFieldData: {
    query: {
      method: 'GET',
      url: `${origin}/api/{entityUrl}`,
      timeout: 5000,
    },
  },
  getAverageFormFieldOptionsBySearch: {
    query: {
      method: 'GET',
      url: `${origin}/api/suggest/{entity}`,
      timeout: 5000,
    },
  },
  postAverageFormFieldData: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/bff/average-price/public/data`,
      timeout: 5000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getDiiaAuthStatus: {
    query: {
      method: 'GET',
      url: `${origin}/users/api/public/diia/check-binding-user/{userId}`,
      timeout: 3000,
    },
  },
  getDiiaAuthLink: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/users/api/public/diia/deeplink`,
      timeout: 5000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getAuthAppStatus: {
    query: {
      method: 'GET',
      url: `${origin}/users/api/public/diia/confirm-status/{token}`,
      timeout: 3000,
    },
  },
  getDiiaShareLink: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/users/api/public/diia/share/deeplink/{userId}`,
      timeout: 5000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getShareAppStatus: {
    query: {
      method: 'GET',
      url: `${origin}/users/api/public/diia/share/check-status/{requestId}`,
      timeout: 5000,
    },
  },
  getConfirmedOwner: {
    query: {
      method: 'GET',
      url: `${origin}/users/api/public/diia/share/check-owner/{userId}`,
      timeout: 3000,
    },
  },
  postBindLoggedUserToDiia: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/users/api/public/diia/bind-logged-user/{userId}`,
      timeout: 5000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getAdvertisementAssignedPhones: {
    query: {
      method: 'GET',
      url: `${origin}/cabinet/api/car/{advertisementId}/assigned-phones`,
      timeout: 5000,
    },
  },
  postAdvertisementAssignedPhones: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/cabinet/api/car/{advertisementId}/assigned-phones`,
      timeout: 5000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getDonationZSU: {
    query: {
      method: 'GET',
      url: `${originAP140}/bff/donations/public/allData`,
      timeout: 5000,
    },
  },
  analyticCheckAdvertisements: {
    query: {
      method: 'GET',
      url: `${origin}/analytics/public/promo/checkAdvertisements`,
      timeout: 2000,
    },
  },
  postCreateUserDonations: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/user/donations/create`,
      timeout: 5000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getInfotechReport: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/infotech/api/public/{type}/{number}/report`,
      timeout: 5000,
    },
  },
  getUSAReportsExist: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/api/reports-usa/public/vins/{vin}/checksum`,
      timeout: 5000,
    },
  },
  postСommentToСall: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/cabinet/api/call/comment`,
      timeout: 3000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getCabinetSlides: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/api/info-blocks/public/type/cabinetSlides`,
      timeout: 3000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getPopUpSlide: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/api/info-blocks/public/type/cabinetPopUp`,
      timeout: 3000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getMainBanner: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/api/info-blocks/public/type/mainBanner`,
      timeout: 3000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getMainBRK: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/api/info-blocks/public/type/mainBRK`,
      timeout: 3000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getMainToday: {
    query: {
      method: 'GET',
      responseType: 'json',
      url: `${origin}/api/info-blocks/public/type/mainToday`,
      timeout: 3000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getAdvertisementQRCode: {
    query: {
      method: 'GET',
      url: `${origin}/qr_code/get_qr/`,
      timeout: 3000,
    },
  },
  getPaymentData: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/api/billing-services/public/paymentData`,
      timeout: 3000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  startApplePaymentSession: {
    query: {
      method: 'POST',
      responseType: 'json',
      url: `${origin}/api/billing-services/public/startPaymentSession`,
      timeout: 3000,
      headers: {
        'Content-type': 'application/json',
      },
    },
  },
  getUserPackagesData: {
    query: {
      method: 'GET',
      url: `${origin}/api/prices/public/verifyings/personified-packages`,
      timeout: 3000,
      headers: {
        'X-Source': 'vue',
      },
    },
  },
  getCompanyDealerData: {
    query: {
      method: 'GET',
      url: `${origin}/company_api/api/landingData`,
      timeout: 3000,
    },
  },
};

module.exports = new LazyAxios(paths);
