export default function () {
  return {
    proAccountLeftBarData: null,
    proAccountStatisticData: null,
    proPhonesAssignmentData: null,
    proSellerPageData: null,
    proBusinessFeaturesData: {},
    proBusinessFeaturesDataById: {},
  };
}
