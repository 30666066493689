import _laxios from '../../_laxios';
import {
  SET_PRO_ACCOUNT_LEFT_BAR_DATA,
  SET_PRO_ACCOUNT_STATISTIC,
  SET_PRO_PHONES_ASSIGNMENT,
  SET_PRO_SELLER_PAGE_DATA,
  SET_BUSINESS_FEATURES_DATA,
  SET_BUSINESS_FEATURES_DATA_BY_ID,
} from '../mutations/mutation-types';

export default {
  fetchProAccountLeftBar: async ({ commit, rootGetters }) => {
    const { 'lang/id': langId } = rootGetters;
    const params = { langId };
    try {
      const response = await _laxios.getProAccountLeftBar.request({ params });
      commit(SET_PRO_ACCOUNT_LEFT_BAR_DATA, response);
    } catch (e) {
      console.error('=== ERROR in fetchProAccountLeftBar ===', e.toString());
    }
  },

  fetchProAccountStatistic: async (
    { commit, rootGetters },
    { from = '', to = '', userId = '' },
    // eslint-disable-next-line consistent-return
  ) => {
    const { 'lang/id': langId } = rootGetters;
    const params = {
      langId,
      from,
      to,
      userId,
    };

    try {
      const response = await _laxios.getProAccountStatistic.request({ params });
      commit(SET_PRO_ACCOUNT_STATISTIC, response);
      return response;
    } catch (e) {
      console.error('=== ERROR in fetchProAccountStatistic ===', e.toString());
    }
  },

  // eslint-disable-next-line consistent-return
  fetchProPhonesAssignment: async ({ commit }) => {
    try {
      const response = await _laxios.getProServiceData
        .expandUrl({ name: 'phonesAssignment' })
        .request();
      commit(SET_PRO_PHONES_ASSIGNMENT, response);
      return response;
    } catch (e) {
      console.error('=== ERROR in fetchProPhonesAssignment ===', e.toString());
    }
  },

  // eslint-disable-next-line consistent-return
  fetchProSellerPage: async ({ commit }, Cookie) => {
    const headers = {};
    if (Cookie) {
      headers.Cookie = Cookie;
    }

    try {
      const response = await _laxios.getProServiceData
        .expandUrl({ name: 'sellerPage' })
        .request({ headers });

      commit(SET_PRO_SELLER_PAGE_DATA, response);

      return response;
    } catch (e) {
      console.error('=== ERROR in fetchProSellerPage ===', e.toString());
    }
  },
  fetchBusinessFeaturesData: async ({ commit }, {name}) => {
    try {
      const response = await _laxios.getBusinessFeaturesData
          .expandUrl({ name })
          .request();
      commit(SET_BUSINESS_FEATURES_DATA, {id: name, payload: response});
      return response;
    } catch (e) {
      console.error('=== ERROR in fetchBusinessFeaturesData ===', e.toString());
    }
  },
  fetchBusinessFeaturesDataOnServer: async ({ commit }, {name, userId}) => {
    try {
      const response = await _laxios.getBusinessFeaturesDataOnServer
          .expandUrl({ name, userId })
          .request();
      commit(SET_BUSINESS_FEATURES_DATA_BY_ID, {id: name, payload: response});
      return response;
    } catch (e) {
      console.error('=== ERROR in fetchBusinessFeaturesDataOnServer ===', e.toString());
    }
  },
};
