import { SET_POPUP_SLIDE_DATA, SET_SLIDERS_DATA } from '../mutations/mutation-types';
import _laxios from '../../../_laxios';

export default {
  async fetchSliders({ commit }, cookies) {
    const headers = {};
    if (cookies) {
      headers.Cookie = cookies;
    }

    try {
      const req = _laxios.getCabinetSlides;
      const cabinetSlides = await req.request({ headers });

      commit(SET_SLIDERS_DATA, cabinetSlides);
    } catch (e) {
      commit(SET_SLIDERS_DATA, []);
      console.error('=== ERROR in fetchSliders ===', e.toString());
    }
  },
  async fetchPopUpSlide({ commit }, cookies) {
    const headers = {};
    if (cookies) {
      headers.Cookie = cookies;
    }

    try {
      const req = _laxios.getPopUpSlide;
      const popUp = await req.request({ headers });

      commit(SET_POPUP_SLIDE_DATA, popUp);
    } catch (e) {
      if (e.response?.status === 404) {
        commit(SET_POPUP_SLIDE_DATA, {});
      } else {
        console.error('=== ERROR in fetchPopUpSlide ===', e.toString());
      }
    }
  },
};
